import Travel from "modules/Travel";

// export const allUserList = '/get-all-users';

export const allUserList = 'v1/users/get-user-list';
export const addUsers = 'v1/users/add-user';
export const getUserDetails = 'v1/users/get-user-detail';

export const editSubdivision = 'v1/users/edit-users';

// TENANT USER 
export const tenantList = 'admin/get-all-users';
export const AddTenant = 'admin/add-user';
export const EditTenant = 'v1/users/edit-users';
export const DeleteTenantByAdmin = 'admin/delete-user/'
export const DeleteTenant = 'v1/users/delete-user/'

// Travel
//export const travelList = 'v1/travel/get-travel-plan-list'
//export const travelList = 'v1/travel/get-travel-status-list'
export const travelList = 'v1/travel/get-travel-list-for-control'
export const activeTravelList = 'v1/travel/get-active-travel'
export const addTravel = 'v1/travel/create-travel-plan'
export const travelLogDetail = 'v1/travel/get-travel-log-details'
export const addTravelCommentURL = 'v1/travel/add-comment'
export const updateTravelStatus = 'v1/travel/change-travel-status'

//User Profile
export const userProfile = "v1/users/get-user-profile"
export const editUserProfile = 'v1/users/edit-user-profile'
export const changePassword = 'v1/users/change-password'
export const getTenantDetails = 'v1/users/get-user-detail';
export const forgotPassword = 'v1/users/forgot-password';

//  IMPORTANT CONTACT
export const importantContactList = 'v1/travel/get-important-contact-list';
export const addImportantContact = 'v1/travel/add-important-contact';
export const updateImportantContact = 'v1/travel/update-important-contact/';
export const removeImportantContact = 'v1/travel/remove-important-contact';

//USEFULLINKFOR TRAVEL
export const usefulLinkforTravelList = 'v1/travel/get-link-for-travel-list'
export const addUsefulLinkforTravel = 'v1/travel/add-link-for-travel';
export const updateUsefulLinkforTravel = 'v1/travel/update-link-for-travel/';
export const removeUsefulLinkforTravel = 'v1/travel/remove-link-for-travel';

//LOCAL POLICE NUMBER
export const localPoliceNumberList = 'v1/travel/get-local-police-number-list';
export const addLocalPoliceNumber = 'v1/travel/add-local-police-number';
export const updateLocalPoliceNumber = 'v1/travel/update-local-police-number/';
export const removeLocalPoliceNumber = 'v1/travel/remove-local-police-number';
export const setLocalPolicedetails = 'v1/travel/get-local-police-number-details/';
export const deleteTravel = 'v1/travel/delete-travel/'


//sub-division users
export const usersList = 'v1/users/get-user-list'
export const Adduser = 'v1/users/add-user';
export const getuserDetails = 'v1/users/get-user-detail';
export const EditUser = 'v1/users/edit-users';

// for Admin dashboard dropdown and connect tenant
export const subdivisionList = 'v1/users/subdivision-list';
export const StatisticsCount = 'admin/get-overall-stats';

export const connectDisconnectSubDiv = 'v1/users/connect-disconnect-subdivision'
export const ConnectedSubDivisions = 'v1/users/connected-subdivisions'


// tennat-user alert
export const alertListCrisisLog = '/v1/alert/get-alert-logs'
export const getAlertDetails = '/v1/alert/get-alert-details/';
export const AddAlertComment = 'v1/alert/add-comment/';

// tenant-user in-house
export const inHouseListCrisisLog = '/v1/alert/get-alert-logs'

//default local police-number
export const defaultLocalPoliceNumber = '/v1/alert/get-single-police-number'

//User Profile
// export const userProfile = "v1/users/get-user-profile"
// export const editUserProfile = 'v1/users/edit-user-profile'
// export const changePassword = 'v1/users/change-password'


// Alert
export const alertList = 'v1/alert/get-all-web-alerts'
export const alertDetail = 'v1/alert/get-alert-details'
export const alertResolve = 'v1/alert/resolve-alert'
export const alertComment = 'v1/alert/add-comment'
// export const updateLocation = 'v1/alert/update-gps-location'
export const alertLogList = 'v1/alert/alert-travel-logs'


export const updateLocation = 'v1/alert/get-latest-position/'
//export const inHouseList = 'v1/alert/get-inhouse-loc-list'
export const inHouseList = 'v1/alert/get-inhouse-loc-list-for-web'
export const addInHouseLocUrl = 'v1/alert/add-inhouse-loc'
export const editInHouseLocUrl = 'v1/alert/edit-inhouse-loc'
export const deleteInHouseLocUrl = 'v1/alert/del-inhouse-loc'
export const inHouselocDetail = 'v1/alert/inhouse-loc-details'

//FocusBoard
export const createActionInFocusBoard = '/v1/focus_board/add-action/'
export const listOfActionsInFocusBoard = '/v1/focus_board/list-of-actions'
export const completeActionInFocusBoard = '/v1/focus_board/complete-action'
export const deleteActionInFocusBoard = '/v1/focus_board/delete-action/'
export const updateFocusBoardURL = "v1/focus_board/edit-action/"

//Checklist
export const checklistURL = "v1/checklist/list-checklist-items"
export const addChecklistURL = "v1/checklist/add-checklist-item"
export const completeChecklistURL = "v1/checklist/complete-checklist-item/"
export const deleteChecklistURL = "v1/checklist/delete-checklist-item/"
export const updateCheclistURL = "v1/checklist/edit-checklist-item/"

// Refresh_Token
export const refresh_token = "v1/users/refresh-token"
